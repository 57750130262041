import axios from "axios";
import { useSelector } from "react-redux";
import Config from "../config/Config";
const PayButton = ({ cartItems, userStatus }) => {
  const user = useSelector((state) => state.auth);

  const handleCheckout = () => {
    axios
      .post(`${Config.BACKEND_URL}/stripe/create-checkout-session`, {
        cartItems,
        userId: userStatus ? user._id : -1,
      })
      .then((response) => {
        if (response.data.url) {
          window.location.href = response.data.url;
        }
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <>
      <button onClick={() => handleCheckout()} >Check out</button>
    </>
  );
};

export default PayButton;
